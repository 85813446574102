import Pubnub from 'pubnub'
import {
  ICustomerSupportCategory,
  ICustomerSupportRequestStatus,
} from 'src/api/customerSupport/index.types'
import { IHouseRequestStatus } from 'src/api/houseRequest/index.types'
import {
  IReportedHouseCategory,
  IReportedHouseRequestStatus,
} from 'src/api/reportedHouse/index.types'

export const API_BASE_PATH = `${window.location.origin}/`

export const routeUrls = {
  login: '/login',
  contactUs: '/contact-us',
  forgotPassword: '/forgot-password',
  resetPassword: '/forgot-password/otp-verification',
  dashboard: '/dashboard',
  businessDirectory: '/dashboard/business-directory',
  viewBusiness: '/dashboard/business-directory/:id',
  customerSupport: '/dashboard/customer-support',
  viewCustomerSupportRequest: '/dashboard/customer-support/:id',
  reportedHouse: '/dashboard/reported-house',
  viewReportedHouseRequest: '/dashboard/reported-house/:id',
  houseRequests: '/dashboard/house-requests',
  viewhouseRequest: '/dashboard/house-requests/:id',
  users: '/dashboard/users',
  settings: '/dashboard/settings',
  management: '/dashboard/management',
  notification: '/dashboard/notification',
}

export interface ISidebarMenuItem {
  label: string
  url: string
  hasChildren?: boolean
}

export const sidebarMenuItems: ISidebarMenuItem[] = [
  {
    label: 'Dashboard',
    url: routeUrls.dashboard,
  },
  {
    label: 'Business Directory',
    url: routeUrls.businessDirectory,
    hasChildren: true,
  },
  {
    label: 'Customer Support',
    url: routeUrls.customerSupport,
    hasChildren: true,
  },
  {
    label: 'Reported House',
    url: routeUrls.reportedHouse,
    hasChildren: true,
  },
  {
    label: 'Requests',
    url: routeUrls.houseRequests,
    hasChildren: true,
  },
  {
    label: 'Users',
    url: routeUrls.users,
  },
  {
    label: 'Settings',
    url: routeUrls.settings,
  },
  {
    label: 'Management',
    url: routeUrls.management,
  },
]

export const businessCategories = [
  'Beauty',
  'Bar',
  'Coffee Shop',
  'Club',
  'Entertainment',
  'Restaurant',
  'Sports',
  'Shopping',
  'Grocery',
]

export const perPageForListing = 8

export const localStorageKeys = {
  accessToken: 'accessToken',
  user: 'user',
}

export const GOOGLE_MAP_API_KEY = 'AIzaSyAFIkJdsMLDaC2_HmEOxF8PQJo0dDOglFU'
export const PUBNUB_PUBLISH_KEY = 'pub-c-cc34d41e-1dd8-4ef2-9952-fa7afa6d3970'
export const PUBNUB_SUBSCRIBE_KEY = 'sub-c-dcf61c22-a8a8-42e2-ad4b-8b53c9178bf3'

export const myPubnub = new Pubnub({
  subscribeKey: PUBNUB_SUBSCRIBE_KEY,
  publishKey: PUBNUB_PUBLISH_KEY,
  uuid: 'ChangeMe',
})

export const houseRequestStatus: {
  pending: IHouseRequestStatus
  approved: IHouseRequestStatus
  requested: IHouseRequestStatus
} = {
  pending: 'Pending',
  approved: 'Approved',
  requested: 'Requested change',
}

export const customerSupportStatusArr: ICustomerSupportRequestStatus[] = [
  'Active',
  'Pending',
  'Resolved',
]

export const customerSupportCategoryArr: ICustomerSupportCategory[] = [
  'App Not Working',
  'Billing',
  'Password/Login',
  'Social Media Integration',
]

export const reportHouseStatusArr: IReportedHouseRequestStatus[] = ['Active', 'Pending', 'Resolved']

export const reportHouseCategoryArr: IReportedHouseCategory[] = [
  'Incident Reporting',
  'Invalid Property Location',
  'Managed Rentals Only',
  'Property Unavailable for Rent',
  'Scam',
  'Subleasing Prohibited',
]
