import React, { useContext } from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Box } from '@mui/material';
import logo from 'src/assets/images/logo.png';
import backgroundImage from '../../assets/images/login-page-image.png'
import { Outlet, useLocation, Navigate } from 'react-router-dom';
import { AuthContext } from 'src/Context/AuthContext'


const LeftBox = styled(Grid)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 1,
    },
}));

const RightBox = styled(Grid)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    [theme.breakpoints.down('md')]: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 0,
    },
}));



const Logo = styled('img')({
    position: 'absolute',
    width: '200px',
    height: '40px',
    left: '110px',
    top: '84px',
});

const LoginLayout = () => {

    const { isAuthenticated, user, accessToken } = useContext(AuthContext);
    const location = useLocation();

    if (isAuthenticated === true && user && accessToken) {
        return <Navigate to="/dashboard" state={{ from: location }} />;
    }
    return (
        <Grid container spacing={0} height='100vh'>
            <LeftBox item sm={12} md={6}>
                <Logo src={logo} alt="Logo" />

                <Outlet />
            </LeftBox>
            <RightBox item sm={12} md={6} />
        </Grid>
    );
};

export default LoginLayout;
